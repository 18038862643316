/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 7/1/2020
 * @Example
 */

import React from "react";
import {withStyles} from "@material-ui/core";
import PropTypes from "prop-types";
import {compose} from "redux";
import Config from "../../config";
import { formatDate } from "devextreme/localization";

const styles = {
    divParent: {
        cursor: 'pointer',
        position: 'relative',
        whiteSpace:"initial"
    },
    stylePopup: {
        fontSize: '1rem',
        fontWeight: 400
    }
};

class HeadClick extends React.Component {
    constructor(props) {
        super(props);
        const column = props.dataHeaderCell && props.dataHeaderCell.column;
        if (props.allowClick && column) {
            column.allowSorting = false;
        }
        this.state = {
            open: false,
        };
    }

    getWorkingDay = (field) => {
        if (!field) return "";
        const lField = field.split("_");
        if (lField && lField.length > 1) {
            return lField[1];
        }
        return "";
    };

    onClick = (e) => {
        const {isExtend, dataHeaderCell, selectedRange, onClick, callbackAfterCopy, conditionCopy, isGroup, isCopyAll, isAllData, onUpdate, allowClick} = this.props;
        //check allow click..
        const _allowClick = typeof allowClick === "function" ? allowClick(dataHeaderCell) : allowClick;
        if (!_allowClick) return false;

        if (!dataHeaderCell || !dataHeaderCell.column || !dataHeaderCell.component) {
            if (onClick) onClick(e, this.props);
        } else {
            if (selectedRange) {
                if (isExtend || (!onClick && Object.keys(selectedRange).length > 0)) {
                    const _ = dataHeaderCell.component || null;
                    const column = dataHeaderCell.column || null;
                    if (!_ && !column) return false;
                    _.saveEditData();
                    const getDataSource = _.getDataSource();
                    let dataSource = [];
                    if(getDataSource.isLoading()) return false;
                    if(isAllData) {
                        dataSource = _.option("dataSource");
                    } else {
                        dataSource = getDataSource.items();
                        if(isGroup) {
                            dataSource = dataSource.map((val) => {
                                return val.items;
                            }).reduce((array,data) => {
                                return array.concat(data);
                            });
                        }
                    }
                    const dataField = column.dataField;
                    let workingDay = this.getWorkingDay(dataField);
                    let _isUpdated = 0;
                    let rowIndx = selectedRange.startRowIndex;
                    const colIndx = selectedRange.startColumnIndex;

                    const vColumns = _.getVisibleColumns();
                    const vCol = vColumns[colIndx];
                    if (vCol && column && vCol.dataField === column.dataField) {
                        const {customDataCopy} = this.props;
                        let dataCopy = _.cellValue(rowIndx, colIndx);
                        if (customDataCopy) {
                            dataCopy = customDataCopy({
                                component: _,
                                column: column,
                                data:     dataSource[rowIndx],
                                rowIndex: rowIndx,
                                colIndex: colIndx,
                                value: dataCopy
                            });
                        }

                        if(isCopyAll) {
                            rowIndx = -1;
                        }

                        let formatText = dataCopy;
                        if (column?.dataType === "date" && column?.format) {
                            formatText = formatDate(dataCopy, column.format);
                        }

                        let msg = Config.lang("Ban_chac_chan_muon_sao_chep_cot_gia_tri_nay?");

                        //if value === "" || value === true || false then not display (request from KieuVan)
                        if(dataCopy !== "" && typeof dataCopy !== "boolean"){
                            msg +=  "</br>" + Config.lang("Gia_tri") + ": " + formatText;
                        }

                        if (workingDay) {
                            Config.popup.show("YES_NO", msg, () => {
                                for (let i = rowIndx + 1; i < dataSource.length; i++) {
                                    const data = dataSource[i];
                                    const condition = conditionCopy ? conditionCopy({data: data, key: dataField}) : true;
                                    if (data[dataField] !== dataCopy
                                        && data["IsProcess_" + workingDay] === 0
                                        && condition
                                    ) {
                                        if (condition) {
                                            data[dataField] = dataCopy;
                                            data.IsUpdated = 1;
                                            onUpdate && onUpdate(data, column);
                                            data["IsUpdate_" + workingDay] = 1;
                                            _isUpdated = 1;
                                        }
                                    }
                                }

                                // dataSource.forEach((d) => {
                                //     if (d[dataField] !== dataCopy && d["IsProcess_" + workingDay] === 0) {
                                //         d[dataField] = dataCopy;
                                //         d.IsUpdated = 1;
                                //         _isUpdated = 1;
                                //     }
                                // });
                                getDataSource.reload();
                                if (callbackAfterCopy) callbackAfterCopy({IsUpdated: _isUpdated});
                            });
                        } else {
                            Config.popup.show("YES_NO", msg, () => {
                                for (let i = rowIndx + 1; i < dataSource.length; i++) {
                                    const data = dataSource[i];
                                    const condition = conditionCopy ? conditionCopy({data: data, key: dataField}) : true;
                                    if (data[dataField] !== dataCopy && condition) {
                                        data[dataField] = dataCopy;
                                        data.IsUpdated = 1;
                                        onUpdate && onUpdate(data, column);
                                        _isUpdated = 1;
                                    }
                                }

                                // _.option("dataSource", dataSource);
                                getDataSource.reload();
                                if (callbackAfterCopy) callbackAfterCopy({IsUpdated: _isUpdated});
                            });
                        }
                    }
                }
            }

            if (onClick) onClick(e, selectedRange);
        }
    };

    render() {
        const {classes, style, className, dataHeaderCell, caption, color, bgColor} = this.props;
        const column = dataHeaderCell.column || null;
        const _style = {
            color: color,
            backgroundColor: bgColor
        };

        const title = caption ? caption : (column && column.caption ? column.caption : "");
        return (
            <div className={"fal-head-click " + classes.divParent + " " + className}
                 style={{...style, ..._style}} onClick={(e) => this.onClick(e)}
            >
                {title}
            </div>
        );
    }
}

HeadClick.defaultProps = {
    isExtensible: false,
    allowClick: false,
    isGroup: false,
};
HeadClick.propTypes = {
    style: PropTypes.any,
    className: PropTypes.string,
    caption: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    color: PropTypes.string,
    bgColor: PropTypes.string,
    isExtend: PropTypes.bool,
    allowClick: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    dataHeaderCell: PropTypes.any,
    selectedRange: PropTypes.object,
    isGroup: PropTypes.bool,
    conditionCopy: PropTypes.func,
    callbackAfterCopy: PropTypes.func,
    onClick: PropTypes.func,
    isCopyAll: PropTypes.bool,
    isAllData: PropTypes.bool,
    customDataCopy: PropTypes.func,
};

export default compose(withStyles(styles))(HeadClick)
