/**
 * @copyright 2019 @ DigiNet
 * @author tranghoang
 * @create 2019/08/09 10:37
 * @update 2017/08/12 10:37
 */

import React from "react";
import Config from "../../config";
import Tooltip from "../common/tooltip/tooltip";
import { Image } from "react-bootstrap";
import { browserHistory } from "react-router";
import Profile from "./profile";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as mainActions from "../../redux/main/main_actions";
import * as homeActions from "../../redux/home/home_actions";
import Search from "../filter/search";
import ActionToolbar from "../common/toolbar/action-toolbar";
import * as Firebase from "./push-notification";
import LinkProducts from "../libs/dgn-core/link-products";
import W09F7000 from "../W0X/W09/W09F7000/W09F7000";
import {
    Badge,
    ButtonIcon,
    Button,
    TabContainer,
    TabHeader,
    TabItem,
    Typography,
    Avatar,
    Divider
} from "diginet-core-ui/components";
import Icon from "diginet-core-ui/icons";
import {useColor} from 'diginet-core-ui/theme';
import moment from "moment";
import _ from "lodash";
import { Loading } from "../common/loading/loading";

const tabIndexMap = new Map([
    ["ALL", 0],
    ["SPECIAL", 1],
    ["NORMAL", 2],
]);
class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabNotify: "ALL",
            notifyLoading: true,
            totalNew: 0,
        };
        this.loadFullNotify = false;
        this.limitNotify = 10;
        this.scrollTop = 0;
        this.dataLoadNotify = {
            limit: this.limitNotify,
            skip: 0,
        };
    }

    getDevice = () => {
        if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf("OPR")) !== -1) {
            return "Opera";
        } else if (navigator.userAgent.indexOf("Chrome") !== -1) {
            return "Chrome";
        } else if (navigator.userAgent.indexOf("Safari") !== -1) {
            return "Safari";
        } else if (navigator.userAgent.indexOf("Firefox") !== -1) {
            return "Firefox";
        } else if (navigator.userAgent.indexOf("MSIE") !== -1 || !!document.documentMode === true) {
            return "IE";
        } else {
            return "unknown";
        }
    };

    getListNotification = () => {
        const { tabNotify } = this.state;
        const param = {
            AppID: Config.env.productID || "",
            limit: this.dataLoadNotify.limit,
            skip: this.dataLoadNotify.skip,
            sort: JSON.stringify([
                { read: "ASC" },
                { createdAt: "DESC" },
                { updatedAt: "DESC" },
                { clearBadge: "ASC" },
            ]),
            ...(tabNotify === "SPECIAL" || tabNotify === "NORMAL" ? { type: tabNotify } : {}),
        };
        this.props.mainActions.getListNotification(param, (err, data) => {
            this.props.mainActions.getBadge();
            this.setState(
                {
                    notifyLoading: false,
                    totalNew: data?.totalNew ?? 0,
                },
                () => {
                    if (data?.rows?.length >= data?.total) {
                        this.loadFullNotify = true;
                    }
                    if (document.getElementsByClassName("popup-content-notify").length > 0) {
                        document.getElementsByClassName("popup-content-notify")[0].scrollTop = this.scrollTop;
                    }
                }
            );
        });
    };

    onBackgroundMessage = () => {
        if (document.visibilityState === "visible") {
            this.reloadNotify(true);
        }
    };

    componentWillUnmount = () => {
        window.removeEventListener("visibilitychange", this.onBackgroundMessage);
    };

    componentDidMount() {
        document.addEventListener("visibilitychange", this.onBackgroundMessage);
        let login = Config.decryptData(Config.getLocalStorage("PROFILEDHR"));
        login = login ? JSON.parse(login) : null;
        // const notification = process && process.env && process.env.REACT_APP_NOTIFY ? process.env.REACT_APP_NOTIFY : 'YES';
        if (login) {
            const config = Config.getSetting("FIREBASE_UI_CONFIG");
            if (config) {
                Firebase.fbInitializeApp();
                Firebase.fbRequestPermission((error, token) => {
                    if (token) {
                        Config.deviceToken = token;
                        let params = {
                            md: this.getDevice(),
                            token: token,
                            os: "WEB",
                            language: Config.language,
                        };
                        this.props.mainActions.addDevice(params);
                    }
                });

                Firebase.fbOnMessage((error, { data }) => {
                    console.log("===========>>fbOnMessage<<===========");
                    // Update lại số "tổng các đề xuất chưa duyệt" trên dashboard
                    const dataPush = JSON.parse(data.payload);
                    // Thêm vào Debugger
                    if (Config.debugger)
                        Config.debugger.pushData({
                            debugger: { startTime: moment().format() },
                            code: 200,
                            data: { ...data, payload: dataPush },
                            route: "Firebase.fbOnMessage",
                        });
                    if (dataPush) {
                        const menuID = dataPush?.menu_id;
                        const menuGroupID = dataPush?.menu_group_id;
                        const dataT = { ...Config.menu };

                        // Tìm group menu id của thông tin push notify
                        const idxMenulistMSSGroup = dataT.listMSS?.findIndex(i => i.MenuGroupID === menuGroupID);
                        const idxMenuOKRGroup = dataT.OKR?.findIndex(i => i.MenuID === menuID);

                        let pathData = null;

                        // tìm thông tin menu của push notify vừa nhận đc trong list menu MSS || OKR
                        if (idxMenulistMSSGroup !== -1) pathData = `listMSS[${idxMenulistMSSGroup}]MenuItem`;
                        //dataT.listMSS?.[idxMenulistMSSGroup]?.MenuItem;
                        else if (idxMenuOKRGroup !== -1) pathData = "OKR"; //dataT.OKR;

                        if (_.get(dataT, pathData, [])?.length) {
                            // Tìm menu id của thông tin push notify
                            const idxMenu = _.get(dataT, pathData, []).findIndex(i => i.MenuID === menuID);
                            if (idxMenu !== -1) {
                                // Gán giá trị NumWaitApproval mới, lấy từ dữ liệu notify trả về (payload.num_wait_approval)
                                _.set(
                                    dataT,
                                    `${pathData}[${idxMenu}].NumWaitApproval`,
                                    dataPush?.num_wait_approval || 0
                                );
                                this.props.homeActions.updateBadgeNumber(dataT);
                            }
                        }
                    }
                    this.reloadNotify();
                });
            }

            this.props.mainActions.getBadge();
        }
    }

    /**
     * Reload data notify, badge, menu.
     * @param reloadMenu {boolean} if true then reload data menu
     */
    reloadNotify = (reloadMenu = false) => {
        // Check đang mở popup danh sách notify tren6 header
        const elNotify = document.querySelector(".popup-notify");
        if (elNotify) {
            // Đang mở thì update lại list notify
            this.getListNotification();
        } else {
            // Update badge number trên header
            this.props.mainActions.getBadge();
        }
        if (reloadMenu) this.props.homeActions.getMenuV2();
    };

    toggleMenuESS = (number, flag = true) => {
        const { toggleMenuEss } = this.props;
        // if(isTab === number) return;
        if (toggleMenuEss) toggleMenuEss(number, flag);
    };

    toggleNav = () => {
        const { toogleSideBar } = this.props;
        if (toogleSideBar) toogleSideBar();
    };

    onChangeTabNotify = (e, value) => {
        this.dataLoadNotify = {
            limit: this.limitNotify,
            skip: 0,
        };
        this.scrollTop = 0;
        this.loadFullNotify = false;
        this.setState(
            {
                tabNotify: value,
                notifyLoading: true,
            },
            () => {
                this.getListNotification();
            }
        );
    };

    onClickNotify = e => {
        if (e && e.form_id) {
            if (this.notify) {
                this.notify.hide();
            }
            const FormID = e && e.FormParentID ? e.FormParentID : e.form_id || "";
            this.toggleMenuESS(Number(e.Type ?? 0), false);
            browserHistory.push({
                pathname: Config.getRootPath() + FormID,
                state: {
                    ...e,
                    voucher_id: e && e.voucher_id ? e.voucher_id : "",
                },
            });
        }
    };

    onOpenedNotify = e => {
        e.style.top = document.documentElement.scrollTop + e.offsetTop;
        document.documentElement.style.overflow = "hidden";
        this.getListNotification();
    };

    onClosedNotify = e => {
        // this.props.mainActions.updateBadge(0);
        document.documentElement.style.overflow = "auto";
    };

    handleScroll = e => {
        this.scrollTop = e.target.scrollTop ? e.target.scrollTop : this.scrollTop;
        let scrollHeight = e.target.offsetHeight;
        let viewHeight = this.listItemNotify.clientHeight;
        if (this.scrollTop + scrollHeight >= viewHeight - 20) {
            if (this.state.notifyLoading || this.loadFullNotify) return;
            this.setState(
                {
                    notifyLoading: true,
                },
                () => {
                    this.dataLoadNotify.limit += this.limitNotify;
                    this.getListNotification();
                }
            );
        }
    };

    redirectToGoalMenu = (pathname, data) => {
        this.toggleMenuESS(2, false);
        if (pathname !== data.FormCall) {
            browserHistory.push(Config.getRootPath() + data.FormCall);
        }
    };

    render() {
        const { isTab, children, badge, listNoty, badgeNumber } = this.props;
        const { tabNotify, notifyLoading } = this.state;
        const pathname = children.props.route.path;
        const logoImg = Config.getSetting("LOGO_URL")
            ? Config.getSetting("LOGO_URL")
            : require("../../assets/images/general/logodhr.svg");

        let dataMenu = [];
        let badgeOKR = 0;

        if (Config.menu) {
            dataMenu = Config.menu.listESS || [];
        }
        if (badgeNumber?.OKR?.length) {
            badgeNumber.OKR.forEach(i => (badgeOKR += Number(i.NumWaitApproval)));
        }

        const transactionMenu = dataMenu.find(i => i.MenuGroupID === "Transaction");
        const goalMenu = !!transactionMenu && transactionMenu.MenuItem.find(i => i.MenuID === "MHRP39N0008");

        return (
            <div className="header-container">
                <div className={"display_row align-center view-logo"}>
                    <div className={"toogle-nav"} id={"toogle-nav"}>
                        <ButtonIcon circular name={"Menu"} viewType={"text"} onClick={this.toggleNav} />
                    </div>
                    <div
                        className={"display_row align-center logo"}
                        onClick={() => {
                            let tab = Number(Config.getLocalStorage("MENUTYPEDHR"));
                            if (tab === 2) tab = 0;
                            this.toggleMenuESS(Number(tab));
                            browserHistory.push({
                                pathname: Config.getRootPath() + "dashboard",
                                state: {
                                    mode: !isTab ? "ESS" : "MSS",
                                },
                            });
                        }}
                    >
                        {Config.getSetting("LOGO_URL") && <Image src={logoImg} />}
                        {!Config.getSetting("LOGO_URL") && (
                            <React.Fragment>
                                <Image src={logoImg} />
                                <span style={{ marginLeft: 5, fontSize: "1.125rem" }}>LemonHR</span>
                            </React.Fragment>
                        )}
                    </div>
                    <div className={"header-filter"}>
                        <Search ref={ref => (Config.filters = ref)} />
                    </div>
                </div>
                <div className={"display_row align-center"}>
                    <W09F7000 toggleMenuESS={this.toggleMenuESS} />
                    {goalMenu && (
                        <ButtonIcon
                            viewType={"text"}
                            color={"danger"}
                            style={{ marginRight: "8px" }}
                            onClick={() => this.redirectToGoalMenu(pathname, goalMenu)}
                        >
                            <Badge
                                invisible={!badgeOKR}
                                color={"danger"}
                                name={"Locate"}
                                iconProps={{ color: "danger" }}
                            />
                        </ButtonIcon>
                    )}
                    <ButtonIcon
                        name={"Guide"}
                        viewType={"text"}
                        color={"primary"}
                        style={{ marginRight: "8px" }}
                        className={"button-guide"}
                        onClick={() =>
                            pathname !== "W09F9000" ? browserHistory.push(Config.getRootPath() + "W09F9000") : false
                        }
                    />
                    <div className={"display_row align-center switch-menu"}>
                        <Button
                            viewType={isTab === 0 ? "filled" : "text"}
                            color={"primary"}
                            label={"ESS"}
                            style={{ width: 80 }}
                            onClick={() => this.toggleMenuESS(0)}
                        />
                        <Button
                            viewType={isTab === 1 ? "filled" : "text"}
                            color={"primary"}
                            label={"MSS"}
                            style={{ width: 80 }}
                            onClick={() => this.toggleMenuESS(1)}
                        />
                    </div>
                    <Tooltip
                        onEntered={this.onOpenedNotify}
                        onExited={this.onClosedNotify}
                        reference={ref => (this.notify = ref)}
                        renderItem={() => {
                            return (
                                <>
                                    <ActionToolbar
                                        style={{ marginTop: -4 }}
                                        className={"pdl15 pdr15"}
                                        alignment={"space-between"}
                                    >
                                    <Typography type={"h1"} style={{whiteSpace: "nowrap", alignSelf: "center"}}>{Config.lang("Thong_bao")}</Typography>
                                </ActionToolbar>
                                <TabContainer tabIndex={tabIndexMap.get(tabNotify)}>
                                    <TabHeader style={{justifyContent: "flex-end"}}>
                                                <TabItem
                                                    index={0}
                                                    style={{ minWidth: 45 }}
                                                    label={Config.lang("Tat_ca")}
                                                    onClick={e => this.onChangeTabNotify(e, "ALL")}
                                                />
                                                <TabItem
                                                    index={1}
                                                    style={{ minWidth: 40 }}
                                                    startIcon={"StarOutline"}
                                                    onClick={e => this.onChangeTabNotify(e, "SPECIAL")}
                                                />
                                                <TabItem
                                                    index={2}
                                                    style={{ minWidth: 40 }}
                                                    startIcon={"Conversation"}
                                                    onClick={e => this.onChangeTabNotify(e, "NORMAL")}
                                                />
                                    </TabHeader>
                                </TabContainer>
                                <Divider color={useColor?.line?.category}/>
                                    <div className={"display_col popup-content-notify"} onScroll={this.handleScroll}>
                                        <Loading visible={notifyLoading} size={"xs"} />
                                        <div ref={ref => (this.listItemNotify = ref)}>
                                            {listNoty?.rows?.map((notify, idx) => {
                                                if (!notify) return null;
                                                const item = notify.payload || {};
                                                const user = Config.getUser({
                                                    UserID: notify?.sender || "",
                                                });

                                                const avatar = user.UserPictureURL || Config.getCDNPath(notify.URL);

                                                const sender_name = user?.EmployeeName || item.sender_name;

                                                const schedule_des =
                                                    Config.language === "01" ? item.schedule_desE : item.schedule_des;

                                                const voucher_des =
                                                    Config.language === "01" ? item.voucher_desE : item.voucher_des;

                                                return (
                                                    <div
                                                        key={idx}
                                                        className={
                                                            "item-notify display_row align-left" +
                                                            (notify.read === "Y" ? "is-read" : "")
                                                        }
                                                        onClick={() => this.onClickNotify(item)}
                                                    >
                                                        <Avatar
                                                            readOnly
                                                            width={35}
                                                            height={35}
                                                            className={"mgr10"}
                                                            alt=""
                                                            src={avatar}
                                                        />
                                                        <div className="display_col">
                                                            <Typography type={"p3"}>
                                                                {notify?.schedule
                                                                    ? schedule_des
                                                                    : `<b>${sender_name}</b>&nbsp;${voucher_des}&nbsp;<b className='mgl1x'>${item.voucher_no}</b>`}
                                                            </Typography>
                                                            <Typography type={"p3"}>
                                                                {Config.convertDate(
                                                                    notify.createdAt,
                                                                    "",
                                                                    "DD/MM/YYYY LT"
                                                                )}
                                                            </Typography>
                                                        </div>
                                                        {idx !== listNoty.rows.length - 1 && (
                                                            <hr className="line-end" />
                                                        )}
                                                    </div>
                                                );
                                            })}
                                            {!listNoty?.rows?.length && (
                                                <div className={"display_row valign-middle pdt15 pdb15"}>
                                                    <div className="text-center">
                                                        <span className="maxline-two text-muted">
                                                            {Config.lang("Khong_co_thong_bao")}
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className={"display_row align-center valign-middle"}>
                                        <Icon
                                            name={"ArrowDown"}
                                            color={listNoty?.rows?.length < listNoty?.total ? "primary" : "dark4"}
                                        />
                                    </div>
                                </>
                            );
                        }}
                        renderCursor={() => {
                            return (
                                <ButtonIcon style={{ marginRight: "8px" }} viewType={"text"}>
                                    <Badge
                                        name={"Notification"}
                                        iconProps={{ color: "primary" }}
                                        color={"danger"}
                                        contentDirection={"right"}
                                        content={badge}
                                        invisible={!badge}
                                    />
                                </ButtonIcon>
                            );
                        }}
                        className="popup-notify popup-tooltip"
                    />

                    {/* Link to products */}
                    <LinkProducts />

                    {!Config.isMobile && (
                        <div className="profile-view">
                            <Profile />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({
        filters: state.main.filters,
        badge: state.main.badge,
        listNoty: state.main.listNoty,
    }),
    dispatch => ({
        mainActions: bindActionCreators(mainActions, dispatch),
        homeActions: bindActionCreators(homeActions, dispatch),
    })
)(Header);
