/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 12/16/2019
 * @Example
 */
import React from "react";
import PropTypes from "prop-types";
import {InputLabel, FormControl} from "@material-ui/core";
import {DropDownBox} from "devextreme-react";
import {withStyles} from "@material-ui/styles";
import Config from "../../config";
import { DropdownBox as DropDownBoxCore } from 'diginet-core-ui/components';

const styles = {
    root: {
        // zIndex: 1502
    },
    rootMobile: {
        margin: '4px 0',
        // zIndex: 1502,
        '& .dx-texteditor.dx-editor-filled': {
            borderRadius: 5,
            '& input': {
                paddingTop: '6px !important'
            }
        }
    },
    dropdownCore: {
        '& > .DGN-UI-InputBase.outlined::after': {
            width: '100%',
            borderTop: 'none',
            borderLeft: 'none',
            borderRight: 'none',
            borderRadius: 'unset',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
        }
    }
};
class Filter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            shrink: (props.value && props.value.length > 0) || props.shrink,
            value: props.value,
            opened: false
        };
        this.isLoaded = false;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {showHeader} = this.props;
        if (showHeader || typeof showHeader === "undefined") {
            Config.filters.setFilter(() => {
                return this.render();
            });
        }
    }

    onTextChanged = (e) => {
        const {onTextChanged} = this.props;
        const value = e?.event?.target?.value || e?.target?.value || '';
        const event = e?.event || e;
        this.setState({value: value});
        if (onTextChanged) onTextChanged(event);
    };

    // onFocusIn = () => {
    //     console.log('focusin');
    //     // this.setState({
    //     //     shrink: true,
    //     // });
    // };
    //
    // onFocusOut = () => {
    //     console.log('focusout');
    //     // this.setState({
    //     //     shrink: !!this.state.value,
    //     // });
    // };

    _onOpened = (e) => {
        const {onOpened, onOpenLoaded} = this.props;
        if (onOpened) onOpened(e);
        if (onOpenLoaded && !this.isLoaded) {
            onOpenLoaded();
            this.isLoaded = true;
        }
        this.setState({opened: true});
    };

    _onClosed = (e) => {
        const {onClosed} = this.props;
        if (onClosed) onClosed(e);
        this.setState({opened: false});
    };

    render() {
        const {value, shrink, opened} = this.state;
        const {
            label, style, className, margin, classes,
            height, placeholder, dataSource, stylingMode, showClearButton, acceptCustomValue,
            openOnFieldClick, showDropDownButton, disabled,
            InputLabelProps, dropdownProps,
            renderFilter, onTextChanged, isUseDDCore
        } = this.props;

        const _showDropDownButton = typeof showDropDownButton !== "undefined" ? showDropDownButton : !!renderFilter;
        const variant = stylingMode === 'underlined' ? "standard" : (stylingMode === 'outlined' ? 'outlined' : 'filled');
        const _shrink = shrink || opened;

        return (
            <React.Fragment>
                <FormControl
                    ref={ref => this.filter = ref}
                    className={className + ' filter-input'}
                    classes={{root: !label ? classes.rootMobile : classes.root}}
                    variant={variant}
                    margin={margin ? margin : "dense"}
                    fullWidth={true} style={style}>
                    <InputLabel
                        shrink={_shrink}
                        style={{
                            marginLeft: 33,
                            padding: '0 14px'
                        }}
                        {...InputLabelProps}
                    >{label}</InputLabel>
                    {!isUseDDCore && <DropDownBox
                        value={value}
                        acceptCustomValue={acceptCustomValue || !!onTextChanged}
                        openOnFieldClick={openOnFieldClick}
                        showDropDownButton={_showDropDownButton}
                        showClearButton={showClearButton}
                        stylingMode={stylingMode ? stylingMode : "filled"}
                        height={height}
                        placeholder={placeholder}
                        dataSource={dataSource}
                        activeStateEnabled={true}
                        deferRendering
                        disabled={disabled}
                        onValueChanged={this.syncTreeViewSelection}
                        onFocusIn={this.onFocusIn}
                        onFocusOut={this.onFocusOut}
                        onOpened={this._onOpened}
                        onClosed={this._onClosed}
                        onKeyUp={this.onTextChanged}
                        buttons={[
                            {
                                name: "search",
                                location: "before",
                                options: {
                                    icon: "search",
                                    stylingMode: "text"
                                }
                            },
                            "dropDown"
                        ]}
                        {...dropdownProps}
                        contentRender={() => {
                            return (
                                <div className={"pd15"} style={{width: '100%', minHeight: 100}}>
                                    {opened && renderFilter()}
                                </div>
                            );
                        }}
                    />}
                    {isUseDDCore && <DropDownBoxCore
                        bgColor
                        openOnClickAt={openOnFieldClick ? "full" : "icon"}
                        placeholder={placeholder}
                        viewType={'outlined'}
                        className={classes.dropdownCore}
                        inputStyle={{ border: 'none' }}
                        inputProps={{
                            onKeyUp: this.onTextChanged,
                        }}
                        onOpened={this._onOpened}
                        onClosed={this._onClosed}
                        {...dropdownProps}
                    >
                        <div style={{ width: '100%', minHeight: 100 }}>
                            {opened && renderFilter()}
                        </div>
                    </DropDownBoxCore>}
                </FormControl>
            </React.Fragment>
        )
    }
}

Filter.propTypes = {
    label: PropTypes.string,
    shrink: PropTypes.bool,
    style: PropTypes.any,
    className: PropTypes.string,
    height: PropTypes.any,
    margin: PropTypes.string,
    disabled: PropTypes.bool,

    value: PropTypes.any,
    acceptCustomValue: PropTypes.bool,
    openOnFieldClick: PropTypes.bool,
    showDropDownButton: PropTypes.bool,
    dataSource: PropTypes.any,
    placeholder: PropTypes.string,
    stylingMode: PropTypes.string,
    showClearButton: PropTypes.bool,
    showHeader: PropTypes.bool,

    renderFilter: PropTypes.any,

    InputLabelProps: PropTypes.any,
    dropdownProps: PropTypes.any,

    onOpenLoaded: PropTypes.func,
    onFocusIn: PropTypes.func,
    onFocusOut: PropTypes.func,
    onOpened: PropTypes.func,
    onClosed: PropTypes.func,

    onTextChanged: PropTypes.func,

    isUseDDCore: PropTypes.bool,
};

Filter.defaultProps = {
    isUseDDCore: false
}

export default withStyles(styles)(Filter);
