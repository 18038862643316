/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 12/16/2019
 * @Example
 */
import React from "react";
import {Dialog, DialogContent} from "@material-ui/core";
import {withStyles} from "@material-ui/styles";
import { ButtonIcon } from "diginet-core-ui/components"
import Config from "../../config";

const styles = {
    paper: {
        padding: 0,
        width: 'calc(100% - 10px)',
        margin: '2px 0 0 0',
        alignSelf: 'flex-start',
    }
};
class Search extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: null,
            load: null,
            openPopupFilter: false
        };
    }

    setFilter = (html) => {
        this.setState({
            filter: html
        });
    };

    toggleFilter = (flag) => {
        this.setState({
            openPopupFilter: flag
        });
    };

    componentWillUnmount() {
        if (!Config.filters) {
            this.setState({
                filter: null
            })
        }
    }

    onEntered = () => {
        setTimeout(() => {
            if (this.dialog) {
                this.dialog.getElementsByClassName("MuiDialog-container")[0].removeAttribute("tabindex");
            }
        }, 400);

    };

    render() {
        const {filter, openPopupFilter} = this.state;

        return (
            <React.Fragment>
                {filter && <>
                <Dialog
                    ref={ref => this.dialog = ref}
                    open={openPopupFilter}
                    fullWidth={true}
                    maxWidth={"lg"}
                    onClose={() => this.toggleFilter(false)}
                    PaperProps={{
                        style: styles.paper
                    }}
                    onEntered={this.onEntered}
                >
                    <DialogContent style={{padding: '0 10px'}} className={"no-margin"}>
                        {filter && typeof filter === "function" ? filter() : filter}
                    </DialogContent>
                </Dialog>
                <div className={"set-filter"} style={{width: '100%'}}>
                    {filter && typeof filter === "function" ? filter() : filter}
                </div>
                <div className={'toogle-filter'}>
                    <ButtonIcon
                        name={"Search"}
                        viewType={"text"}
                        color={"primary"}
                        onClick={() => this.toggleFilter(true)}
                    />
                </div>
                </>}
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(Search);