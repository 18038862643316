/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 2019/03/12 13:49
 * @update 2019/03/12 13:49
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Dropdown, MenuItem} from "react-bootstrap";
import DropdownToggle from "react-bootstrap/es/DropdownToggle";
import DropdownMenu from "react-bootstrap/es/DropdownMenu";

class DropdownToolbar extends Component {


    clickButton(value){
        if(value){
            if(value.onClick&&!value.disabled){
                value.onClick()
            }
        }
    }

    render() {
        const {icon, buttonName,menuItem,disabled} = this.props;
        return (
            <Dropdown disabled={disabled}  id={'dropdown-toolbar'}>
                <DropdownToggle className='btn-crm' open={true}>{icon &&
                <i className={icon&&icon}>&nbsp;&nbsp;&nbsp;</i>}{buttonName&&buttonName}</DropdownToggle>
                <DropdownMenu >
                    {menuItem&&menuItem.map((value, index) => {
                        return (
                            <MenuItem disabled={value.disabled}  key={index} onClick={()=>{this.clickButton(value)}}> {value.icon&&<i className={`${value.icon} mgr5`}/>}{value.name}</MenuItem>
                        )
                    })}

                </DropdownMenu>
            </Dropdown>
        );
    }
}


DropdownToolbar.propTypes = {
    buttonName: PropTypes.string,
    className: PropTypes.string,
    icon:PropTypes.string,
    disabled:PropTypes.bool,
    menuItem: PropTypes.array,
};

export default DropdownToolbar;