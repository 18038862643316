/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 9/11/2020
 * @update 6/9/2022
 * @Example
 */

import { Popover } from "diginet-core-ui/components";
import PropTypes from "prop-types";
import React from "react";

const PopoverAction = React.memo(props => {
    const {
        style,
        className,
        popoverProps,
        direction,
        position,
        width,
        maxWidth,
        visible,
        target,
        reference,
        children,
        onHiding,
        onHidden,
        onClose,
    } = props;

    return (
        <Popover
            ref={reference}
            style={style}
            className={className}
            anchor={target}
            direction={direction || position}
            width={width || maxWidth}
            open={visible}
            onClose={e => {
                onHiding && onHiding(e);
                onHidden && onHidden(e);
                onClose && onClose(e);
            }}
            {...popoverProps}
        >
            {children}
        </Popover>
    );
});

PopoverAction.propTypes = {
    style: PropTypes.any,
    className: PropTypes.string,
    reference: PropTypes.any,
    target: PropTypes.any,
    direction: PropTypes.string,
    position: PropTypes.string, //is deprecated
    visible: PropTypes.bool,
    maxWidth: PropTypes.any, //is deprecated
    width: PropTypes.any,

    onHiding: PropTypes.func, //is deprecated
    onHidden: PropTypes.func, //is deprecated
    onClose: PropTypes.func,
};

export default PopoverAction;
